import AmazonLogo from '../images/amazon.svg';
import AppleLogo from '../images/apple.png';
import YoutubeLogo from '../images/youtube.png';
import GoogleLogo from '../images/google.png';
import VuduLogo from '../images/vudu.png';

export const purchaseData = [
  {
    name: 'Apple TV',
    countries:{
      "ae": "https://itunes.apple.com/ae/movie/deported/id1499602868",
      "ag": "https://itunes.apple.com/ag/movie/deported/id1499602868",
      "ai": "https://itunes.apple.com/ai/movie/deported/id1499602868",
      "al": "https://itunes.apple.com/al/movie/deported/id1499602868",
      "am": "https://itunes.apple.com/am/movie/deported/id1499602868",
      "ao": "https://itunes.apple.com/ao/movie/deported/id1499602868",
      "ar": "https://itunes.apple.com/ar/movie/deported/id1499602868",
      "at": "https://itunes.apple.com/at/movie/deported/id1499602868",
      "au": "https://itunes.apple.com/au/movie/deported/id1499602868",
      "az": "https://itunes.apple.com/az/movie/deported/id1499602868",
      "bb": "https://itunes.apple.com/bb/movie/deported/id1499602868",
      "be": "https://itunes.apple.com/be/movie/deported/id1499602868",
      "bf": "https://itunes.apple.com/bf/movie/deported/id1499602868",
      "bg": "https://itunes.apple.com/bg/movie/deported/id1499602868",
      "bh": "https://itunes.apple.com/bh/movie/deported/id1499602868",
      "bj": "https://itunes.apple.com/bj/movie/deported/id1499602868",
      "bm": "https://itunes.apple.com/bm/movie/deported/id1499602868",
      "bn": "https://itunes.apple.com/bn/movie/deported/id1499602868",
      "bo": "https://itunes.apple.com/bo/movie/deported/id1499602868",
      "br": "https://itunes.apple.com/br/movie/deported/id1499602868",
      "bs": "https://itunes.apple.com/bs/movie/deported/id1499602868",
      "bt": "https://itunes.apple.com/bt/movie/deported/id1499602868",
      "bw": "https://itunes.apple.com/bw/movie/deported/id1499602868",
      "by": "https://itunes.apple.com/by/movie/deported/id1499602868",
      "bz": "https://itunes.apple.com/bz/movie/deported/id1499602868",
      "ca": "https://itunes.apple.com/ca/movie/deported/id1499602868",
      "cg": "https://itunes.apple.com/cg/movie/deported/id1499602868",
      "ch": "https://itunes.apple.com/ch/movie/deported/id1499602868",
      "cl": "https://itunes.apple.com/cl/movie/deported/id1499602868",
      "cn": "https://itunes.apple.com/cn/movie/deported/id1499602868",
      "co": "https://itunes.apple.com/co/movie/deported/id1499602868",
      "cr": "https://itunes.apple.com/cr/movie/deported/id1499602868",
      "cv": "https://itunes.apple.com/cv/movie/deported/id1499602868",
      "cy": "https://itunes.apple.com/cy/movie/deported/id1499602868",
      "cz": "https://itunes.apple.com/cz/movie/deported/id1499602868",
      "de": "https://itunes.apple.com/de/movie/deported/id1499602868",
      "dk": "https://itunes.apple.com/dk/movie/deported/id1499602868",
      "dm": "https://itunes.apple.com/dm/movie/deported/id1499602868",
      "do": "https://itunes.apple.com/do/movie/deported/id1499602868",
      "dz": "https://itunes.apple.com/dz/movie/deported/id1499602868",
      "ec": "https://itunes.apple.com/ec/movie/deported/id1499602868",
      "ee": "https://itunes.apple.com/ee/movie/deported/id1499602868",
      "eg": "https://itunes.apple.com/eg/movie/deported/id1499602868",
      "es": "https://itunes.apple.com/es/movie/deported/id1499602868",
      "fi": "https://itunes.apple.com/fi/movie/deported/id1499602868",
      "fj": "https://itunes.apple.com/fj/movie/deported/id1499602868",
      "fm": "https://itunes.apple.com/fm/movie/deported/id1499602868",
      "fr": "https://itunes.apple.com/fr/movie/deported/id1499602868",
      "gb": "https://itunes.apple.com/gb/movie/deported/id1499602868",
      "gd": "https://itunes.apple.com/gd/movie/deported/id1499602868",
      "gh": "https://itunes.apple.com/gh/movie/deported/id1499602868",
      "gm": "https://itunes.apple.com/gm/movie/deported/id1499602868",
      "gr": "https://itunes.apple.com/gr/movie/deported/id1499602868",
      "gt": "https://itunes.apple.com/gt/movie/deported/id1499602868",
      "gw": "https://itunes.apple.com/gw/movie/deported/id1499602868",
      "gy": "https://itunes.apple.com/gy/movie/deported/id1499602868",
      "hk": "https://itunes.apple.com/hk/movie/deported/id1499602868",
      "hn": "https://itunes.apple.com/hn/movie/deported/id1499602868",
      "hr": "https://itunes.apple.com/hr/movie/deported/id1499602868",
      "hu": "https://itunes.apple.com/hu/movie/deported/id1499602868",
      "id": "https://itunes.apple.com/id/movie/deported/id1499602868",
      "ie": "https://itunes.apple.com/ie/movie/deported/id1499602868",
      "il": "https://itunes.apple.com/il/movie/deported/id1499602868",
      "in": "https://itunes.apple.com/in/movie/deported/id1499602868",
      "is": "https://itunes.apple.com/is/movie/deported/id1499602868",
      "it": "https://itunes.apple.com/it/movie/deported/id1499602868",
      "jm": "https://itunes.apple.com/jm/movie/deported/id1499602868",
      "jo": "https://itunes.apple.com/jo/movie/deported/id1499602868",
      "jp": "https://itunes.apple.com/jp/movie/deported/id1499602868",
      "ke": "https://itunes.apple.com/ke/movie/deported/id1499602868",
      "kg": "https://itunes.apple.com/kg/movie/deported/id1499602868",
      "kh": "https://itunes.apple.com/kh/movie/deported/id1499602868",
      "kn": "https://itunes.apple.com/kn/movie/deported/id1499602868",
      "kr": "https://itunes.apple.com/kr/movie/deported/id1499602868",
      "kw": "https://itunes.apple.com/kw/movie/deported/id1499602868",
      "ky": "https://itunes.apple.com/ky/movie/deported/id1499602868",
      "kz": "https://itunes.apple.com/kz/movie/deported/id1499602868",
      "la": "https://itunes.apple.com/la/movie/deported/id1499602868",
      "lb": "https://itunes.apple.com/lb/movie/deported/id1499602868",
      "lc": "https://itunes.apple.com/lc/movie/deported/id1499602868",
      "lk": "https://itunes.apple.com/lk/movie/deported/id1499602868",
      "lr": "https://itunes.apple.com/lr/movie/deported/id1499602868",
      "lt": "https://itunes.apple.com/lt/movie/deported/id1499602868",
      "lu": "https://itunes.apple.com/lu/movie/deported/id1499602868",
      "lv": "https://itunes.apple.com/lv/movie/deported/id1499602868",
      "md": "https://itunes.apple.com/md/movie/deported/id1499602868",
      "mg": "https://itunes.apple.com/mg/movie/deported/id1499602868",
      "mk": "https://itunes.apple.com/mk/movie/deported/id1499602868",
      "ml": "https://itunes.apple.com/ml/movie/deported/id1499602868",
      "mn": "https://itunes.apple.com/mn/movie/deported/id1499602868",
      "mo": "https://itunes.apple.com/mo/movie/deported/id1499602868",
      "mr": "https://itunes.apple.com/mr/movie/deported/id1499602868",
      "ms": "https://itunes.apple.com/ms/movie/deported/id1499602868",
      "mt": "https://itunes.apple.com/mt/movie/deported/id1499602868",
      "mu": "https://itunes.apple.com/mu/movie/deported/id1499602868",
      "mw": "https://itunes.apple.com/mw/movie/deported/id1499602868",
      "mx": "https://itunes.apple.com/mx/movie/deported/id1499602868",
      "my": "https://itunes.apple.com/my/movie/deported/id1499602868",
      "mz": "https://itunes.apple.com/mz/movie/deported/id1499602868",
      "na": "https://itunes.apple.com/na/movie/deported/id1499602868",
      "ne": "https://itunes.apple.com/ne/movie/deported/id1499602868",
      "ng": "https://itunes.apple.com/ng/movie/deported/id1499602868",
      "ni": "https://itunes.apple.com/ni/movie/deported/id1499602868",
      "nl": "https://itunes.apple.com/nl/movie/deported/id1499602868",
      "np": "https://itunes.apple.com/np/movie/deported/id1499602868",
      "no": "https://itunes.apple.com/no/movie/deported/id1499602868",
      "nz": "https://itunes.apple.com/nz/movie/deported/id1499602868",
      "om": "https://itunes.apple.com/om/movie/deported/id1499602868",
      "pa": "https://itunes.apple.com/pa/movie/deported/id1499602868",
      "pe": "https://itunes.apple.com/pe/movie/deported/id1499602868",
      "pg": "https://itunes.apple.com/pg/movie/deported/id1499602868",
      "ph": "https://itunes.apple.com/ph/movie/deported/id1499602868",
      "pk": "https://itunes.apple.com/pk/movie/deported/id1499602868",
      "pl": "https://itunes.apple.com/pl/movie/deported/id1499602868",
      "pt": "https://itunes.apple.com/pt/movie/deported/id1499602868",
      "pw": "https://itunes.apple.com/pw/movie/deported/id1499602868",
      "py": "https://itunes.apple.com/py/movie/deported/id1499602868",
      "qa": "https://itunes.apple.com/qa/movie/deported/id1499602868",
      "ro": "https://itunes.apple.com/ro/movie/deported/id1499602868",
      "ru": "https://itunes.apple.com/ru/movie/deported/id1499602868",
      "sa": "https://itunes.apple.com/sa/movie/deported/id1499602868",
      "sb": "https://itunes.apple.com/sb/movie/deported/id1499602868",
      "sc": "https://itunes.apple.com/sc/movie/deported/id1499602868",
      "se": "https://itunes.apple.com/se/movie/deported/id1499602868",
      "sg": "https://itunes.apple.com/sg/movie/deported/id1499602868",
      "si": "https://itunes.apple.com/si/movie/deported/id1499602868",
      "sk": "https://itunes.apple.com/sk/movie/deported/id1499602868",
      "sl": "https://itunes.apple.com/sl/movie/deported/id1499602868",
      "sn": "https://itunes.apple.com/sn/movie/deported/id1499602868",
      "sr": "https://itunes.apple.com/sr/movie/deported/id1499602868",
      "st": "https://itunes.apple.com/st/movie/deported/id1499602868",
      "sv": "https://itunes.apple.com/sv/movie/deported/id1499602868",
      "sz": "https://itunes.apple.com/sz/movie/deported/id1499602868",
      "tc": "https://itunes.apple.com/tc/movie/deported/id1499602868",
      "td": "https://itunes.apple.com/td/movie/deported/id1499602868",
      "th": "https://itunes.apple.com/th/movie/deported/id1499602868",
      "tj": "https://itunes.apple.com/tj/movie/deported/id1499602868",
      "tm": "https://itunes.apple.com/tm/movie/deported/id1499602868",
      "tn": "https://itunes.apple.com/tn/movie/deported/id1499602868",
      "tr": "https://itunes.apple.com/tr/movie/deported/id1499602868",
      "tt": "https://itunes.apple.com/tt/movie/deported/id1499602868",
      "tw": "https://itunes.apple.com/tw/movie/deported/id1499602868",
      "tz": "https://itunes.apple.com/tz/movie/deported/id1499602868",
      "ua": "https://itunes.apple.com/ua/movie/deported/id1499602868",
      "ug": "https://itunes.apple.com/ug/movie/deported/id1499602868",
      "us": "https://itunes.apple.com/us/movie/deported/id1499602868",
      "uy": "https://itunes.apple.com/uy/movie/deported/id1499602868",
      "uz": "https://itunes.apple.com/uz/movie/deported/id1499602868",
      "vc": "https://itunes.apple.com/vc/movie/deported/id1499602868",
      "ve": "https://itunes.apple.com/ve/movie/deported/id1499602868",
      "vg": "https://itunes.apple.com/vg/movie/deported/id1499602868",
      "vn": "https://itunes.apple.com/vn/movie/deported/id1499602868",
      "ye": "https://itunes.apple.com/ye/movie/deported/id1499602868",
      "za": "https://itunes.apple.com/za/movie/deported/id1499602868",
      "zw": "https://itunes.apple.com/zw/movie/deported/id1499602868"
    }, 
    image: AppleLogo,
    buyPrice: 5.99,
    rentPrice: 4.99,
	target:''
  },
  {
    name: 'Amazon',
    countries:{
      'us':'https://www.amazon.com/dp/B084TMTBKF',
      'gb':'https://www.amazon.co.uk/dp/B084TMCNWK'
    }, 
    image: AmazonLogo,
	buyPrice: 5.99,
    rentPrice: 3.99,
	target:'_blank'
  },
  {
    name: 'Youtube',
   countries:{
		'us':'https://www.youtube.com/watch?v=RZmdYQ18G6Y',
		'ca':'https://www.youtube.com/watch?v=pSSsQVqms60',
		'nz':'https://www.youtube.com/watch?v=5IEv5kEe0ko',
		'gb':'https://www.youtube.com/watch?v=ZsuPECg8PFg',
		'za':'https://www.youtube.com/watch?v=zTdUUmQMIF4',
		'au':'https://www.youtube.com/watch?v=GIjsmidI2Aw',
		'ie':'https://www.youtube.com/watch?v=dxF5W0JA_Xc',
		'mt':'https://www.youtube.com/watch?v=0XK2EisOfL0',
		'mx':'https://www.youtube.com/watch?v=HyPK4ZP57EE',
		'ar':'https://www.youtube.com/watch?v=10o1U1NByNU'
    }, 
    image: YoutubeLogo,
    buyPrice: 5.99,
    rentPrice: 3.99,
	target:'_blank'
  },
  {
    name: 'Google Play',
    countries:{
      'us':'https://play.google.com/store/movies/details/?id=6CBYoOzjN3M.P',
      'ca':'https://play.google.com/store/movies/details/?id=SOUq0Wwg2J4.P',        
      'gb':'https://play.google.com/store/movies/details/?id=q6rM_ZFrj6A.P',        
      'ie':'https://play.google.com/store/movies/details/?id=cM2fuxy1Eok.P',        
      'mt':'https://play.google.com/store/movies/details/?id=SW86NM2jh-U.P',        
      'au':'https://play.google.com/store/movies/details/?id=oT5ayyGvs50.P',        
      'za':'https://play.google.com/store/movies/details/?id=ig04yn5SWNc.P',        
      'nz':'https://play.google.com/store/movies/details/?id=0In0dPelmTs.P',        
      'ar':'https://play.google.com/store/movies/details/?id=yle8mBVcbtI.P',        
      'mx':'https://play.google.com/store/movies/details/?id=myUU0QICSiQ.P'        
    }, 
    image: GoogleLogo,
    buyPrice: 5.99,
    rentPrice: 3.99,
	target:'_blank'
  },
  {
    name: 'Vudu',
    countries:{
		'us':'https://www.vudu.com/content/movies/details/Deported/1387751'
    }, 
    image: VuduLogo,
    buyPrice: 5.99,
    rentPrice: 3.99,
	target:'_blank'
  },
];
