import React, { Fragment, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import SEO from '../components/SEO';
import { orderStrings } from '../consts/strings';
import mq from '../style/mq';
import { Container, Inner } from '../components/Elements';
import { colors } from '../consts/style';
import { purchaseData } from '../consts/data';
import axios from 'axios';

const Wrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 7.2rem;
  left: 0;
  height: calc(100% - 7.2rem);
  width: 100%;

  ${Container} {
    position: relative;
    z-index: 5;
    height: 100%;
    width: 100%;
  }
  ${Inner} {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 0;
`;

const orderQuery = graphql`
  {
    bg: file(relativePath: { eq: "buy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const Content = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40rem;
  background: ${colors.red};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 2.4rem;
  color: white;
  h1 {
    font-size: 4rem;
    text-transform: uppercase;
    font-family: 'Black Ops One';
    font-weight: normal;
  }
  h2 {
    font-size: 3rem;
    text-transform: uppercase;
    font-family: 'Black Ops One';
    font-weight: normal;
  }

  .item-wrap {
    width: 100%;
    background: white;
    display: flex;
    width: 100%;
    margin-bottom: 0.4rem;
    border-radius: 0.4rem;
    min-height: 90px;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
    }
    p {
      margin: 0;
      color: ${colors.blue};
      text-decoration: none;
    }
    img {
      max-height: 80px;
      max-width: 80px;
    }
  }

  ${mq.tabletSmall`
    width: 100%;
    overflow: scroll;
    background: #ef2a2a73;
  `}
`;

export default function OrderNow() {

const [countryCode, setCountryCode] = useState();
useEffect(() => {
  async function fetchMyAPI() {
    console.log('I m here');
    await axios.get('https://ipapi.co/json/')
    .then((response) => {
      if(response.data.country_code){
		setCountryCode(response.data.country_code);
	  } else {
		setCountryCode('us');
	  }
    })
   }
  fetchMyAPI();
  });
  const data = useStaticQuery(orderQuery);
  return (
    <Fragment>
      <SEO title={orderStrings.title} description={orderStrings.description} />
      <Wrapper>
        <BgImg
          backgroundColor={colors.blue}
          fluid={data.bg.childImageSharp.fluid}
        />
        <Content>
          <h1>Order Now</h1>

          {countryCode && purchaseData.map((pd, i) => (
            <a
			 target={pd.target}
              href={pd.countries[countryCode.toLowerCase()]}
              key={i}
              className={`item-wrap ${!pd.countries[countryCode.toLowerCase()] && 'disabled'}`}
            >
              <div className="image">
                <img src={pd.image} alt={pd.name} />
              </div>
              <div className="pricing">
                {pd.countries[countryCode.toLowerCase()] ? (
                  <Fragment>
                    <p>
                      <strong>Buy:</strong> ${pd.buyPrice}
                    </p>
                    <p>
                      <strong>Rent:</strong> ${pd.rentPrice}
                    </p>
                  </Fragment>
                ) : (
                  <p>Coming soon</p>
                )}
              </div>
            </a>
          ))}
        </Content>
      </Wrapper>
    </Fragment>
  );
}
